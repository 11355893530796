<template>
  <div class="notification">
    <div class="notification__title text-semibold">
      {{ date }}
    </div>
    <div class="notification__body" v-html="text"></div>
  </div>
</template>

<script>
export default {
  name: "NotificationItem",
  props: {
    date: String,
    text: String,
  },
};
</script>

<style lang="scss" scoped>
.notification {
  width: 100%;
  min-height: 120px;
  background: #FFFFFF;
  box-shadow: 0 3px 10px 3px rgba(43, 72, 105, 0.11);
  border-radius: 8px;
  margin-bottom: 10px;

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid var(--grey-light-1);
  }

  &__body {
    padding: 16px 20px;
  }
}
</style>