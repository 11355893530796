<template>
  <VLoader v-if="_fetchingStatus === requestStatus.PENDING" />
  <div v-else-if="_fetchingStatus === requestStatus.SUCCESS" class="notifications-list">
    <div
        v-for="item in Object.entries(_notificationsList)"
        :key="item[0]"
    >
      <div class="notifications-list__title text-xxl">
        <div class="notifications-list__date">
          {{ item[0] }}
        </div>
      </div>
      <NotificationItem
          v-for="notif in item[1]"
          :key="notif.id"
          :date="notif.date"
          :text="notif.text"
      />
    </div>
    <VListIsEmpty v-if="!Object.entries(_notificationsList).length"/>
  </div>
</template>

<script>
import VListIsEmpty from '@/js/components/common/VListIsEmpty';
import NotificationItem from '@/js/components/pages/settings/notifications/NotificationItem';
import { requestStatus } from '@/js/utils/constants';

export default {
  name: "Notifications",
  components: { VListIsEmpty, NotificationItem },
  data: () => ({
    requestStatus,
  }),
  computed: {
    _fetchingStatus() {
      return this.$store.state.modalNotifications.fetchingStatus;
    },
    _notificationsList() {
      return this.$store.state.modalNotifications.notificationsStatic;
    }
  },
  mounted() {
    this.getNotifications();
  },
  methods: {
    getNotifications() {
      if (this._fetchingStatus === this.requestStatus.IDLE) {
        this.$store.dispatch('modalNotifications/getNotifications');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.notifications-list {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;

  &__title {
    position: relative;
    width: 100%;
    height: 1px;
    background-color: var(--grey-light-1);
    margin: 60px auto 40px;
  }

  &__date {
    --width: 120px;
    --height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--width);
    height: var(--height);
    border: 1px solid var(--grey-light-1);
    border-radius: 25px;
    font-size: 12px;
    color: var(--grey-main);
    position: absolute;
    left: calc(50% - var(--width) / 2);
    top: calc(var(--height) / 2 * -1);
    background-color: var(--grey-light);
  }
}
</style>